import React from 'react';
import AssetDashboard from './pages/AssetDashboard'

function App() {
  return (
    <div>
      <AssetDashboard />
    </div>
  );
}

export default App;