import React, { useState } from 'react';
import axios from 'axios';

function NetWorthDashboard() {
  const [assets, setAssets] = useState({
    checking: 0,
    savings: 0,
    stocks: 0,
    crypto: 0,
    retirement_accts: 0,
    other: 0,
  });

  const [liabilities, setLiabilities] = useState({
    creditCardDebt: 0,
    studentLoans: 0,
    mortgages: 0,
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send the data to the backend server using axios
    axios.post('/api/net-worth', { assets, liabilities })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div>
      <h1>Moolah Mage</h1>
      <p>currently work in progress</p>
      <div>
        <h2>Assets</h2>
        <form>
          <label htmlFor="checking">Checking:</label>
          <input
            type="number"
            id="checking"
            value={assets.checking}
            onChange={(e) => setAssets({ ...assets, checking: parseInt(e.target.value) })}
          />
          <label htmlFor="savings">Savings:</label>
          <input
            type="number"
            id="savings"
            value={assets.savings}
            onChange={(e) => setAssets({ ...assets, savings: parseInt(e.target.value) })}
          />
          <label htmlFor="stocks">Stocks:</label>
          <input
            type="number"
            id="stocks"
            value={assets.stocks}
            onChange={(e) => setAssets({ ...assets, stocks: parseInt(e.target.value) })}
          />
        </form>
      </div>
      <div>
        <h2>Liabilities</h2>
        <form>
          <label htmlFor="creditCardDebt">Credit Card Debt:</label>
          <input
            type="number"
            id="creditCardDebt"
            value={liabilities.creditCardDebt}
            onChange={(e) => setLiabilities({ ...liabilities, creditCardDebt: parseInt(e.target.value) })}
          />
          <label htmlFor="studentLoans">Student Loans:</label>
          <input
            type="number"
            id="studentLoans"
            value={liabilities.studentLoans}
            onChange={(e) => setLiabilities({ ...liabilities, studentLoans: parseInt(e.target.value) })}
          />
          <label htmlFor="mortgages">Mortgages:</label>
          <input
            type="number"
            id="mortgages"
            value={liabilities.mortgages}
            onChange={(e) => setLiabilities({ ...liabilities, mortgages: parseInt(e.target.value) })}
          />
        </form>
      </div>
      <form onSubmit={handleSubmit}>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default NetWorthDashboard;